import { createContext, useContext, useEffect, useState } from "react";
import type { getUnreadCount } from "~/models/.server/activities";
import type { getUserById } from "~/models/.server/users";

declare global {
  interface Window {
    ReactNativeWebView?: {
      postMessage: (message: string) => void;
    };
  }
}

type unreadCount = Awaited<ReturnType<typeof getUnreadCount>>;
type UserType = NonNullable<Awaited<ReturnType<typeof getUserById>>> & {
  unreadCount: unreadCount;
};

export const UserContext = createContext<UserType | null>(null);

export function UserProvider({
  user,
  children,
}: {
  user: UserType | null;
  children: React.ReactNode;
}) {
  const [userId, setUserId] = useState<number | null | undefined>();

  useEffect(() => {
    if (user) {
      setUserId(user.id);
    } else {
      setUserId(null);
    }
  }, [user]);

  // Send the user ID to the native app
  useEffect(() => {
    if (window.ReactNativeWebView && userId !== undefined) {
      const message = JSON.stringify({
        type: "user",
        userId,
      });
      window.ReactNativeWebView.postMessage(message);
    }
  }, [userId]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

export function useUser() {
  return useContext(UserContext);
}
